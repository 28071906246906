<template>
  <span class="error"><slot /></span>
</template>

<style lang="scss" scoped>
.error {
  color: red;
  display: inline-block;
  padding: 0.25rem 0.5rem;
}
</style>

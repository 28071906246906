<script setup lang="ts">
type CalloutButtonType = {
  tag?: "NuxtLink" | "button";
  type?: "button" | "submit" | "reset" | undefined;
  primary?: boolean;
  secondary?: boolean;
  cta?: boolean;
};

const props = withDefaults(defineProps<CalloutButtonType>(), {
  tag: "NuxtLink",
  type: "button",
});

const buttonType = computed(() => {
  return props.cta ? "cta" : props.secondary ? "secondary" : "primary";
});
</script>

<template>
  <button v-if="tag === 'button'" :type="type" class="the-button" :class="buttonType">
    <slot />
  </button>
  <NuxtLink v-else to="" class="the-button" :class="buttonType"><slot /></NuxtLink>
</template>

<style lang="scss">
$border-width: 2px;
button.the-button {
  padding: 1.25rem 6rem;
}

a.the-button {
  padding: 1.5rem 6rem;
}

.the-button {
  border-radius: 100px;

  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 2rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.5s ease-out;

  &.cta {
    background-image: linear-gradient(#ffffff, #ffffff);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 0% 100%;
    border: 2px solid white;
    transition:
      background-size 0.5s,
      color 0.5s;

    &:hover {
      background-size: 100% 100%;
      color: black;
    }
  }

  &.primary {
    background-color: var(--primary-color);
    border: $border-width solid var(--primary-color);
    &:hover {
      background-color: white !important;
      color: black;
    }
  }

  &.secondary {
    background-color: black;
    border: $border-width black solid;
    &:hover {
      background-color: white !important;
      color: black;
    }
  }
}
</style>
